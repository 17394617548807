
import { IonContent, IonPage, IonButton, IonIcon } from '@ionic/vue'
import { defineComponent, ref } from 'vue'
import { arrowForwardOutline } from 'ionicons/icons'

import MainLangSelection from '../components/MainLangSelection.vue'
import TransLangSelection from '../components/TransLangSelection.vue'
import { useRouter } from 'vue-router'
import { updateSettings } from '@/settings'

export default defineComponent({
  name: 'Onboarding',
  components: {
    IonPage,
    IonButton,
    IonContent,
    IonIcon,
    MainLangSelection,
    TransLangSelection
  },
  setup() {
    const step = ref(1)
    const router = useRouter()

    async function goToNext() {
      if (step.value === 1) {
        step.value = 2
      } else {
        await updateSettings({
          onboarding: false
        })
        router.push('/alle-kapitel')
      }
    }

    return {
      step,
      arrowForwardOutline,
      goToNext
    }
  }
})
