
import { getLanguages } from '@/db'
import {
  IonLabel,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption
} from '@ionic/vue'
import { defineComponent } from 'vue'
import { settings, updateSettings } from '../settings'

export default defineComponent({
  components: { IonLabel, IonList, IonItem, IonSelect, IonSelectOption },
  methods: {
    async changeLanguage(language: string) {
      updateSettings({ primaryLanguage: language })
    }
  },
  setup() {
    return {
      languages: getLanguages(),
      settings
    }
  }
})
