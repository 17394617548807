import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ion_list, { class: "" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
          return (_openBlock(), _createBlock(_component_ion_item, {
            class: "flex",
            key: language.val
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_checkbox, {
                class: "mr-4",
                "onUpdate:modelValue": ($event: any) => (_ctx.setLanguage(language.id, $event)),
                modelValue: _ctx.settings.secondaryLanguages.includes(language.id)
              }, null, 8, ["onUpdate:modelValue", "modelValue"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(language.label), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}